<template>
    <div class="admin-competition-reports overflow-hidden full-height">
        <print-css :entry="printEntry" :contact="printContact" :printer="true"></print-css>
        <v-container fluid class="pa-6 full-height">
            <h3>{{ competition.title + ` - Printing Page` }}</h3>
            <app-table
                ref="table"
                :loading="loading"
                :title="competition.title + ` - Printing Page`"
                :items="reactiveEntries"
                hide-actions
                :headers="headers"
                :items-per-page="15"
                @close="close"
                @export-csv="exportCSV"
                @getImage="getImage"
                @getLabel="getLabel"
                @checkSend="checkSend"
            ></app-table>
        </v-container>
    </div>
</template>

<script>
// Services

// Components
import AppTable from "@/components/table/app-table.vue";
import PrintCss from "@/components/layout/print-css.vue";
import Entry from "@/modules/app-module/entry";
import ScoreCard from "@/modules/app-module/score-card";
import Contact from "@/modules/app-module/contact";
import Competition from "@/modules/app-module/competition";
export default {
    name: "admin-competition-reports",

    meta: {
        title: "print.competition",
    },

    components: {
        PrintCss,
        AppTable,
    },

    props: {},

    data() {
        return {
            printContact: null,
            printEntry: null,
            awards: [],
            cache: 0,
            tab: 0,
            loading: true,
            selected: [],
            tags: [],
            entries: [],
            scorecards: [],
            contacts: [],
            competition: {},
            filtered: [],
            processTitle: "",
            originalHeaders: [
                {
                    text: "Image",
                    value: "image",
                    // parent: "data",
                    type: "image-cell",
                    width: "1%",
                },
                {
                    text: "Medium",
                    value: "printingMedium",
                    parent: "data",
                    type: "text-cell",
                    width: "1%",
                },
                {
                    text: "Actions",
                    value: "action",
                    buttons: [
                        {
                            icon: "mdi-download",
                            function: "getImage",
                        },
                        {
                            icon: "mdi-postage-stamp",
                            function: "getLabel",
                        },
                        {
                            icon: "mdi-email-check-outline",
                            function: "checkSend",
                        },
                    ],
                    type: "action-cell",
                    width: "20%",
                    sortable: false,
                    align: "center",
                    color: "primary",
                },
                {
                    text: "Printed",
                    value: "printed",
                    // parent: "data",
                    type: "boolean-cell",
                    width: "1%",
                },

                {
                    text: "Return Print",
                    value: "return",
                    // parent: "data",
                    type: "boolean-cell",
                    width: "1%",
                },
                {
                    text: "Sent",
                    value: "printSent",
                    // parent: "data",
                    type: "boolean-cell",
                    width: "1%",
                },
                {
                    text: "Entry Code",
                    value: "entryCode",
                    // parent: "data",
                    type: "text-cell",
                    width: "1%",
                },
                {
                    text: "Artist",
                    value: "managedAuthor",
                    // parent: "data",
                    type: "text-cell",
                    width: "1%",
                },
                {
                    text: "Email Address",
                    value: "emails",
                    // parent: "data",
                    type: "text-cell",
                    width: "1%",
                    isArray: true,
                },
                {
                    text: "Phone Number",
                    value: "phone",
                    // parent: "data",
                    type: "text-cell",
                    width: "1%",
                    isArray: true,
                },
                {
                    text: "Address",
                    value: "address",
                    // parent: "data",
                    type: "text-cell",
                    width: "40%",
                },

                // {
                //     text: "Category",
                //     value: "category",
                //     // parent: "data",
                //     type: "text-cell",
                //     width: "1%",
                // },
            ],
            headers: [],
            form: {
                fields: [
                    {
                        title: "Status",
                        key: "status",
                    },
                ],
            },
        };
    },

    computed: {
        exportItems() {
            return this.selected.length ? this.selected : this.filtered.length ? this.filtered : this.entries;
        },
        reactiveEntries() {
            return this.entries;
        },
        menu() {
            let menu = [];

            menu.push({
                icon: "mdi-file-delimited-outline",
                text: "Export CSV",
                function: "export-csv",
            });

            return menu;
        },
        isOnline() {
            return this.competition?.data?.judgingType == "online";
        },
        isLive() {
            return this.competition?.data?.judgingType == "live";
        },
    },

    methods: {
        async getImage(item) {
            // window.open(`https://api.appbooks.com/download/${item}`, "_blank");

            var link = document.createElement("a");
            const token = this.$fluro.auth.getCurrentToken();
            const imageId = item.data.image._id;

            //Update to printed
            const payload = {
                data: {
                    printed: true,
                },
            };

            await Entry.update(item._id, payload);

            let index = this.entries.findIndex((p) => p._id == item._id);
            if (index !== -1) {
                // Make a copy of the object and update the copy's data.printed key
                let updated = { ...this.entries[index] };
                updated.printed = true;
                // Replace the object in the array with the updated object
                this.entries.splice(index, 1, updated);
            }

            if (link.download !== undefined) {
                link.setAttribute(
                    "href",
                    `https://api.appbooks.com/download/${imageId}?redirect=false&access_token=${token}`
                );
                link.setAttribute("download", `${item.title} award.png`);
                link.style = "visibility:hidden";
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        },
        async checkSend(item) {
            console.log("sending", item);
            //update in fluro
            const payload = {
                data: {
                    printSent: !item?.printSent,
                },
            };

            await Entry.update(item._id, payload);

            let index = this.entries.findIndex((p) => p._id == item._id);
            if (index !== -1) {
                // Make a copy of the object and update the copy's data.printed key
                let updated = { ...this.entries[index] };
                updated.printSent = !item?.printSent;
                // Replace the object in the array with the updated object
                this.entries.splice(index, 1, updated);
            }
        },
        getLabel(item) {
            this.loading = true;
            this.printEntry = item;
            this.printContact = item.contact;
            //This is needed as sometimes the image doesnt load the right row
            setTimeout(async () => {
                window.print();

                this.loading = false;
            }, 500);
        },
        reset() {
            this.selected = [];
            // this.$refs.table.selected = this.selected;

            this.entries = [];
            this.contacts = [];
            this.competition = {};
            this.filtered = [];
            this.headers = [...new Set(this.originalHeaders)];
        },
        async init() {
            this.loading = true;

            await this.$fluro.resetCache();

            this.reset();

            try {
                // Competition
                this.competition = await Competition.get(this.$route.params.id).then(({ data }) => data);

                //////////////////////////////////////////////////

                // Entries

                let entryQuery = {
                    _type: "article",
                    definition: "entry",
                    status: {
                        $in: ["active", "draft", "archived"],
                    },
                    $or: [
                        {
                            "data.competition": this.$route.params.id,
                        },
                        {
                            "data.competition": `ObjectId(${this.$route.params.id})`,
                        },
                        {
                            "data.competition._id": this.$route.params.id,
                        },
                        {
                            "data.competition._id": `ObjectId(${this.$route.params.id})`,
                        },
                    ],
                };

                let params = {};

                if (this.isOnline) {
                    params.appendPosts = "all";
                }

                this.entries = await Entry.query(entryQuery, { params }).then(({ data }) => data);

                this.entries = this.entries.filter((p) => p.data.printingPaid == true && p.data.image); //Filter by printing paid

                //////////////////////////////////////////////////

                // Contacts

                let userIds = this.entries.map(({ managedAuthor }) => managedAuthor._id);
                userIds = [...new Set(userIds)];

                let contactQuery = {
                    _type: "contact",
                    "data.personaId": {
                        $in: userIds,
                    },
                };

                this.contacts = await Contact.query(contactQuery).then(({ data }) => data);

                //////////////////////////////////////////////////

                if (this.isOnline) {
                    await this.getOnlineScores();
                }

                if (this.isLive) {
                    let ids = this.entries.map(({ _id }) => `ObjectId(${_id})`);

                    let scorecardQuery = {
                        _type: "article",
                        definition: "scoreCard",
                        status: {
                            $in: ["active", "draft", "archived"],
                        },
                        "data.entry": {
                            $in: ids,
                        },
                    };

                    this.scorecards = await ScoreCard.query(scorecardQuery).then(({ data }) => data);
                    console.log("SCORECARDS", this.scorecards);

                    this.getLiveScores();
                }

                console.log("ENTRIES", this.entries);
                console.log("COMPETITION", this.competition);
            } catch (err) {
                console.log("ERROR", err);
            }

            this.loading = false;
        },
        async getOnlineScores() {
            // Getting the judges from the competition

            let categoryJudges = this?.competition?.data?.categoryJudges || [];

            let judges = categoryJudges?.map(({ judges }) => judges)?.flat() || [];
            judges = [...new Map(judges.map((item) => [item["_id"], item])).values()];

            //////////////////////////////////////////////////

            // Touching up the entries
            const returnFeePaid = this.competition.data.returnFeePaid || ["647010272d142930189d3567"];
            this.entries = await Promise.all(
                this.entries.map(async (entry) => {
                    let { image, category, entryCode } = entry.data;

                    let foundContact = this.contacts.find(
                        (contact) => contact?.data?.personaId == entry?.managedAuthor?._id
                    );
                    const returnPaid = returnFeePaid.includes(entry.managedOwners[0]._id);

                    //////////////////////////////////////////////////

                    // Setup entry
                    let obj = {
                        ...entry,
                        image,
                        entryCode,
                        return: returnPaid || false,
                        contact: foundContact || "",
                        medium: entry?.data?.printingMedium?.title || "No Medium Found",
                        managedAuthor: entry?.managedAuthor?.title || "",
                        printed: entry?.data?.printed || false,
                        printSent: entry?.data?.printSent || false,
                        emails: foundContact?.emails || "",
                        phone: foundContact?.phoneNumbers[0] || "",
                        address: foundContact?.data?.address || "",
                        category: category?.title || "",
                        entrant: foundContact?.data?.entrant || "",
                    };

                    //////////////////////////////////////////////////

                    // Add scores/abstains

                    let scores = entry?.posts?.score?.posts || [];
                    let submitted = scores.filter((score) => score?.data?.submitted) || [];
                    let abstains = entry?.posts?.abstain?.posts || [];

                    let total = 0;
                    let scoreArray = [];

                    judges.forEach((judge) => {
                        let score = scores?.find((score) => score?.managedAuthor == judge?._id);
                        let abstain = abstains?.find((abstain) => abstain?.managedAuthor == judge._id);

                        let awaitingScore =
                            !score &&
                            categoryJudges.some(
                                (item) =>
                                    (item?.category?._id || item?.category) ==
                                        (entry?.data?.category?._id || entry?.data?.category) &&
                                    item?.judges.some((itemJudge) => (itemJudge?._id || itemJudge) == judge?._id)
                            );

                        obj[judge._id] = abstain
                            ? "Abstained"
                            : awaitingScore
                            ? "Awaiting score"
                            : score
                            ? score?.data
                            : "";

                        if (score?.data?.submitted) {
                            //Custom Scoring
                            scoreArray.push(score?.data?.score || 0);
                            total += parseInt(score?.data?.score) || 0;
                        }
                    });
                    //Custom scoring uses a string from fluro as custom JS code to made the calcs
                    const payload = {
                        numbers: scoreArray,
                    };

                    //Final score will always be the calculation they have chosen
                    let calculatedScore = await this.$fluro.api
                        .post(`/reaction/spark/${this.competition.data.formula._id}`, payload)
                        .then(({ data }) => data);

                    let average = Math.round(total / submitted.length).toFixed(2);

                    //////////////////////////////////////////////////
                    obj.calculated = calculatedScore.result;
                    obj.average = average;
                    obj.total = total;

                    //////////////////////////////////////////////////

                    return obj;
                })
            );
        },
        getLiveScores() {
            // Touching up the entries

            this.entries = this.entries.map((entry) => {
                let { image, category, entryCode } = entry.data;

                let foundContact = this.contacts.find(
                    (contact) => contact?.data?.personaId == entry?.managedAuthor?._id
                );

                //////////////////////////////////////////////////

                // Setup entry

                let obj = {
                    ...entry,
                    tagsArray: entry?.tags?.map(({ title }) => title) || [],
                    image,
                    entryCode,
                    managedAuthor: entry?.managedAuthor?.title || "",
                    score: entry?.data?.score || "",
                    cameraUsed: entry?.data?.cameraUsed || "",
                    finalised: entry?.data?.finalised || false,
                    published: entry?.data?.published || false,
                    emails: foundContact?.emails || "",
                    address: foundContact?.data?.address || "",
                    category: category?.title || "",
                    entrant: foundContact?.data?.entrant || "",
                    studentAward: foundContact?.data?.studentAward || false,
                    masterAward: foundContact?.data?.masterAward || false,
                    grandMasterAward: foundContact?.data?.grandMasterAward || false,
                };

                //////////////////////////////////////////////////

                // Add judges

                //////////////////////////////////////////////////

                // Add scorecards

                let scorecards = this.scorecards.filter((card) => card?.data?.entry?._id == entry._id);

                if (scorecards.length) {
                    let scorecard = scorecards.sort((a, b) => b?.data?.round - a?.data?.round)[0];

                    obj.round = scorecard?.data?.round;
                    obj.average = scorecard?.data?.average;
                    obj.calculated = scorecard?.data?.calculated;
                    obj.modified = scorecard?.data?.modified;
                    obj.reviewed = scorecard?.data?.reviewed;
                }

                //////////////////////////////////////////////////

                return obj;
            });

            if (this.scorecards?.length) {
                this.headers.push(
                    {
                        text: "Round Finalised",
                        value: "round",
                        type: "text-cell",
                        width: "1%",
                    },
                    {
                        text: "Average Score",
                        value: "average",
                        type: "score-cell",
                        width: "1%",
                    },
                    {
                        text: "Calculated Score",
                        value: "calculated",
                        type: "score-cell",
                        width: "1%",
                    },
                    {
                        text: "Modified Score",
                        value: "modified",
                        type: "score-cell",
                        width: "1%",
                    },
                    {
                        text: "Reviewed",
                        value: "reviewed",
                        type: "boolean-cell",
                        width: "1%",
                    },
                    {
                        text: "Final Score",
                        value: "score",
                        type: "score-cell",
                        width: "1%",
                    },
                    {
                        text: "Finalised",
                        value: "finalised",
                        type: "boolean-cell",
                        width: "1%",
                    },
                    {
                        text: "Published",
                        value: "published",
                        type: "boolean-cell",
                        width: "1%",
                    }
                );
            }
        },
        close() {
            this.$router.push({ name: "admin.competition", params: { id: this.$route.params.id } });
        },
        getFiltered(e) {
            this.filtered = e;
        },
        async exportCSV() {
            let data = {
                title: this.competition.title,
                items: this.exportItems,
                headers: this.headers,
            };

            await this.$app.exportCSVItems(data);
        },
    },

    async mounted() {
        await this.init();
    },
};
</script>

<style lang="scss"></style>
